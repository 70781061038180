import React from 'react';
import Layout from '../components/layout/Layout';
import LabelText from '../components/LabelText';
import { Link } from 'gatsby';
import Header2 from '../components/layout/Header2';
import { useIntl } from 'gatsby-plugin-intl';
import { window } from 'browser-monads';

const PrivacyPolicyPage = () => {
  const language = window.location.pathname.substring(0, 3);
  const intl = useIntl();
  const privacyPolicyTitle = intl.formatMessage({ id: 'privacy.titre' });
  const privacyIntro = intl.formatMessage({ id: 'privacy.Introduction' });
  const privacyParagraph1 = intl.formatMessage({ id: 'privacy.p-1' });
  const privacyParagraph2 = intl.formatMessage({ id: 'privacy.p-2' });
  const privacyParagraph3 = intl.formatMessage({ id: 'privacy.p-3' });
  const privacyParagraph4 = intl.formatMessage({ id: 'privacy.p-4' });
  const privacyParagraph5 = intl.formatMessage({ id: 'privacy.p-5' });
  const privacyParagraph6 = intl.formatMessage({ id: 'privacy.p-6' });
  const privacyParagraph7 = intl.formatMessage({ id: 'privacy.p-7' });
  const privacyParagraph8 = intl.formatMessage({ id: 'privacy.p-8' });
  const privacyParagraph9 = intl.formatMessage({ id: 'privacy.p-9' });
  const privacyParagraph10 = intl.formatMessage({ id: 'privacy.p-10' });
  const privacyParagraph11 = intl.formatMessage({ id: 'privacy.p-11' });
  const privacyParagraph12 = intl.formatMessage({ id: 'privacy.p-12' });
  const privacyParagraph13 = intl.formatMessage({ id: 'privacy.p-13' });
  const privacyParagraph14 = intl.formatMessage({ id: 'privacy.p-14' });
  const privacyParagraph15 = intl.formatMessage({ id: 'privacy.p-15' });
  const privacyParagraph16 = intl.formatMessage({ id: 'privacy.p-16' });
  const privacyParagraph17 = intl.formatMessage({ id: 'privacy.p-17' });
  const privacyParagraph18 = intl.formatMessage({ id: 'privacy.p-18' });
  const privacyParagraph19 = intl.formatMessage({ id: 'privacy.p-19' });
  const privacyParagraph20 = intl.formatMessage({ id: 'privacy.p-20' });
  const privacyParagraph21 = intl.formatMessage({ id: 'privacy.p-21' });
  const privacyParagraph22 = intl.formatMessage({ id: 'privacy.p-22' });
  const privacyParagraph23 = intl.formatMessage({ id: 'privacy.p-23' });
  const privacyParagraph24 = intl.formatMessage({ id: 'privacy.p-24' });
  const goBack = intl.formatMessage({ id: 'goback' });

  return (

    <Layout>
      <Header2 />
      <div className='mentions'>
        <LabelText className='mb-8 text-gray-600 text-xl lg:text-3xl text-center'>{privacyPolicyTitle}</LabelText>
        <LabelText className='mb-8 text-gray-600 text-lg lg:text-2xl text-center'>{privacyIntro}</LabelText>
        <div className='bloc'>
          <p className='text-lg'> {privacyParagraph1} <span className='enlight'>Art'n'Green</span>{privacyParagraph2}<span className='enlight'>805 Chemin du Pey de la Salle, 83440 Fayence, FRANCE</span>{privacyParagraph3}</p>
          <p className='text-lg'><span className='titreSection'><b>{privacyParagraph5}</b></span></p>
          <p className='text-lg'>{privacyParagraph4}<span className='enlight'>www.artngreen-paysages.com</span>{privacyParagraph6}</p>

          <p className='text-lg'><span className='titreSection enlight'>{privacyParagraph7}</span></p>
          <p className='text-lg'>{privacyParagraph8}</p>
          <p className='text-lg'><span className='titreSection enlight'>{privacyParagraph9}</span></p>
          <p className='text-lg'>{privacyParagraph10}</p>
          <p className='text-lg'><span className='titreSection'><b>{privacyParagraph11}</b></span></p>
          <p className='text-lg'>{privacyParagraph12}<span className='enlight'>Art'n'Green</span></p>
          <p className='text-lg'>{privacyParagraph13}</p>
          <p className='text-lg'><span className='titreSection'><b>{privacyParagraph14}</b></span></p>
          <p className='text-lg'>{privacyParagraph15}</p>

          <p className='text-lg'><span className='titreSection'>{privacyParagraph16}</span></p>
          <p className='text-lg'>{privacyParagraph17}</p>

          <p className='text-lg'><span className='titreSection'><b>{privacyParagraph18}</b></span></p>
          <p className='text-lg'>{privacyParagraph19}</p>

          <p className='text-lg'>{privacyParagraph20}</p>
          <p className='text-lg'><span className='titreSection'>{privacyParagraph21}</span></p>
          <p className='text-lg'>{privacyParagraph22}</p>
          <p className='text-lg'><span className='titreSection'>{privacyParagraph23}</span></p>
          <p className='text-lg'>{privacyParagraph24}</p>
        </div>
      </div>
      <div className='goBack'>
        <Link className='mb-8 text-gray-600 text-3xl text-center' to={language + '/'}>{goBack}</Link>
      </div>
    </Layout>
  );
};
export default PrivacyPolicyPage;
